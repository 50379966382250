.Testimonials{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0 2rem;
}

.left-t{
    display: flex;
    width: fit-content;
    flex: 1 1;
    gap: 2rem;
    flex-direction: column;
    text-transform: uppercase;

}

.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-t>:nth-child(2){
    font-size: 3rem;
    font-weight: bold;
}
.left-t>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
    color: white;
}

.left-t>:nth-child(4){
    color: white;
    text-transform: none;
}

.left-t>:nth-child(5){
    color: var(--orange);
    text-transform: none;
}


.left-t>:nth-child(6){
    text-transform: none;
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}

.right-t img{
    width: 85%;
}

@media screen and (max-width: 768px){
    .Testimonials{
     flex-direction: column;   
    }
    .left-t>:nth-child(2),.left-t>:nth-child(3){
        font-size: xx-large;
    }
    .right-t{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .right-t>div{
        position: relative;
        display: none;
    }
    .right-t>img{
        top: 0;
        position: relative;
        align-self: center;

    }
    .right-t>:last-child{
        display: block;
        bottom: 0;
        left: 0;
    }
}